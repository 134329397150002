import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { Button } from '@wh-components/core/Button/Button'
import ArrowLongLeft from '@wh-components/icons/ArrowLongLeft'
import { css } from 'styled-components'
import { zIndexSidebarHeader } from '@bbx/search-journey/sub-domains/search/lib/zIndices'

interface PhoneFilterSidebarHeaderProps {
    onDismiss: () => void
    showResetButton: boolean
    onReset: () => void
}

export const PhoneFilterSidebarHeader: FunctionComponent<PhoneFilterSidebarHeaderProps> = (props) => (
    <Box
        position="sticky"
        top={0}
        left={0}
        height={50}
        width="100%"
        display={{ phone: 'flex', tablet: 'none' }}
        alignItems="center"
        justifyContent="space-between"
        background="semantic.surface"
        borderBottom="owl"
        css={css`
            z-index: ${zIndexSidebarHeader};
        `}
    >
        <Button
            onClick={props.onDismiss}
            Icon={ArrowLongLeft}
            variant="transparent"
            color="complimentary"
            size="large"
            flexShrink="0"
            aria-label="Filter schließen"
            testId="sidebar-header-back-button"
        >
            <Box marginLeft="xs">
                <Text fontSize="xl" fontWeight="bold" color="palette.verydarkgrey">
                    Filtern
                </Text>
            </Box>
        </Button>
        {props.showResetButton && (
            <Button onClick={props.onReset} variant="outline" size="small" marginHorizontal="s" testId="sidebar-header-reset-button">
                Suche zurücksetzen
            </Button>
        )}
    </Box>
)
