import React, { Fragment, FunctionComponent, ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react'

import { minSearchResultsOnPageForDesktopResultListFilterAd } from '@wh/common/digital-advertising/config'
import { FilterAdLargeRenderSlot } from '@wh/common/digital-advertising/components/FilterAdLargeRenderSlot/FilterAdLargeRenderSlot'
import { formatNumber } from '@wh/common/chapter/lib/formatter'
import { BlockBodyScroll } from '@wh-components/core/BlockBodyScroll/BlockBodyScroll'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { CreateSearchAgentButtonWithText } from '@bbx/search-journey/sub-domains/search/components/common/common/CreateSearchAgentButton/CreateSearchAgentButtonWithText'
import { PhoneFilterSidebarHeader } from '@bbx/search-journey/sub-domains/search/components/common/result-list/FilterSidebar/FilterSideBarHeader'
import {
    CreateSearchAgentButton,
    SearchAgentUIState,
} from '@bbx/search-journey/sub-domains/search/components/common/common/CreateSearchAgentButton/CreateSearchAgentButton'
import { zIndexOuterBox, zIndexSearchButton } from '@bbx/search-journey/sub-domains/search/lib/zIndices'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { Text } from '@wh-components/core/Text/Text'
import { Box } from '@wh-components/core/Box/Box'
import { createGlobalStyle, css } from 'styled-components'
import { FIXED_HEADER_HEIGHT_PHONE } from '@wh/common/chapter/lib/config/constants'
import { useUserAgent } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'
import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import { useHideDelay } from '@wh/common/chapter/hooks/useHideDelay'
import { useRerenderIfKeyboardIsOpened } from '@wh/common/chapter/lib/useRerenderIfKeyboardIsOpened'
import { ScrollContainerProvider } from '@wh/common/chapter/components/ScrollContainerProvider/ScrollContainerProvider'
import { hideWhenKeyboardIsOpen } from '@wh/common/chapter/lib/hideWhenKeyboardIsOpen'

interface FilterSideBarProps {
    canResetSearch: boolean
    resetSearch: () => void
    showFilterSideBar: boolean
    setShowFilterSideBar: (value: boolean) => void
    searchAgentCreateLink: ContextLink | undefined
    searchAgentOptionsLink: ContextLink | undefined
    setSearchAgentUIState: React.Dispatch<React.SetStateAction<SearchAgentUIState>>
    currentSearchResult: SearchResult
    navigators: ReactNode
    scrollAbleFilterSideBarRef: RefObject<HTMLDivElement>
}

const transitionDuration = 300

export const FilterSideBar: FunctionComponent<FilterSideBarProps> = ({
    canResetSearch,
    resetSearch,
    setShowFilterSideBar,
    showFilterSideBar,
    currentSearchResult,
    searchAgentCreateLink,
    searchAgentOptionsLink,
    setSearchAgentUIState,
    navigators,
    scrollAbleFilterSideBarRef,
}) => {
    const stickyFooterViewPortThresholdRef = useRef<HTMLDivElement>(null)
    const footerRef = useRef<HTMLDivElement>(null)

    const rowsFound = currentSearchResult.rowsFound

    const scrollUp = useCallback(() => {
        if (scrollAbleFilterSideBarRef.current) {
            scrollAbleFilterSideBarRef.current.scrollTop = 0
        }
    }, [scrollAbleFilterSideBarRef])

    const isOldiOS = useIsOldiOS()
    const isPhone = useResponsiveValue({ phone: true, tablet: false }, false)
    const filterSidebarVisibilityHidden = !useHideDelay(showFilterSideBar, transitionDuration)
    useRerenderIfKeyboardIsOpened()

    return (
        <Fragment>
            {/* INFO the box below is shown as flex so the box containing the filter ad expands to the full height, this is necessary for the sticky filter ad */}
            <Box
                flex="0 0 250px"
                minWidth="0"
                display={{ tablet: 'flex' }}
                flexDirection="column"
                marginRight="m"
                aria-hidden={isPhone ? !showFilterSideBar : false}
                css={css`
                    /* reduces page jumping when content ads are loaded since scroll anchoring will now use the first cut off ad row instead */
                    overflow-anchor: none;
                    ${(p) => p.theme.media.only.phone} {
                        position: fixed;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        background-color: ${(p) => p.theme.colors.semantic.surface.DEFAULT};
                        transition: transform ${transitionDuration / 1000}s 0s;
                        will-change: transform;
                        transform: ${showFilterSideBar ? 'translateX(0%)' : 'translateX(-110%)'};
                        z-index: ${zIndexOuterBox};
                        /* makes children untabbable */
                        visibility: ${filterSidebarVisibilityHidden ? 'hidden' : undefined};
                    }
                `}
            >
                <PhoneFilterSidebarHeader
                    onDismiss={() => setShowFilterSideBar(false)}
                    showResetButton={canResetSearch}
                    onReset={() => {
                        resetSearch()
                        scrollUp()
                    }}
                />
                <CreateSearchAgentButtonWithText
                    searchAgentCreateLink={searchAgentCreateLink}
                    searchAgentOptionsLink={searchAgentOptionsLink}
                    setSearchAgentUIState={setSearchAgentUIState}
                    testId="create-search-agent-button"
                    clickActionEvent="search_result_list_search_agent_click_top"
                    taggingData={currentSearchResult.taggingData}
                />

                <Box
                    position="relative"
                    // needs to be `scroll`, not `auto` so `-webkit-overflow-scrolling` works correctly
                    overflowY={{ phone: 'scroll', tablet: 'visible' }}
                    height={{
                        phone: `calc(100% - ${(footerRef.current?.offsetHeight ?? 0) + FIXED_HEADER_HEIGHT_PHONE}px)`,
                        tablet: 'auto',
                    }}
                    ref={scrollAbleFilterSideBarRef}
                    css={css`
                        /* enable momentum/inertia scrolling on iOS */
                        -webkit-overflow-scrolling: touch;
                    `}
                >
                    <ScrollContainerProvider scrollElement={scrollAbleFilterSideBarRef.current}>{navigators}</ScrollContainerProvider>

                    {searchAgentCreateLink && rowsFound > 1 && (
                        <Box
                            testId="detail-search-footer-create-search-agent-with-results"
                            display={{ phone: 'block', tablet: 'none' }}
                            marginHorizontal="m"
                            marginTop="m"
                            paddingBottom="l"
                        >
                            <Box textAlign="center" marginBottom="s">
                                <Text fontSize="s" display="block">
                                    Wir suchen für dich weiter
                                </Text>
                                <Text fontSize="s" display="block">
                                    Erhalte <strong>neue Anzeigen</strong> per Benachrichtigung
                                </Text>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <CreateSearchAgentButton
                                    maxWidth="400px"
                                    searchAgentCreateLink={searchAgentCreateLink}
                                    searchAgentOptionsLink={searchAgentOptionsLink}
                                    setSearchAgentUIState={setSearchAgentUIState}
                                    size="large"
                                    variant="outline"
                                    clickActionEvent="search_result_list_search_agent_click_bottom"
                                    taggingData={currentSearchResult.taggingData}
                                />
                            </Box>
                        </Box>
                    )}
                    <Box position="absolute" height="1px" visibility="hidden" ref={stickyFooterViewPortThresholdRef} />
                </Box>

                {currentSearchResult.rowsReturned >= minSearchResultsOnPageForDesktopResultListFilterAd && (
                    <Box display={{ phone: 'none', desktop: 'block' }} paddingTop="l" paddingBottom="m" flex="1 1 auto">
                        {/* INFO this is positionend sticky which makes the parent div the sticky container in which this div is allowed to move - also the top space is a bit bigger to leave space for the sticky SearchAgent button */}
                        <Box position="sticky" top="90px">
                            <FilterAdLargeRenderSlot />
                        </Box>
                    </Box>
                )}

                <Box
                    ref={footerRef}
                    testId="result-list-sidebar-footer"
                    display={{ phone: 'block', tablet: 'none' }}
                    position="fixed"
                    bottom="0"
                    width={{ phone: '100%' }}
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    paddingVertical="s"
                    paddingHorizontal="m"
                    // eslint-disable-next-line no-restricted-syntax
                    boxShadow="-2px 0 4px 0 rgba(0, 0, 0, 0.5)"
                    css={css`
                        z-index: ${zIndexSearchButton};
                        background-color: ${(p) => p.theme.colors.palette.polarbear};
                        border-top: 1px solid ${(p) => p.theme.colors.palette.owl};
                        ${hideWhenKeyboardIsOpen}
                    `}
                >
                    <Box maxWidth="400px" justifyContent="center" alignItems="center" margin="0px auto">
                        <ClientRoutingAnchorLink
                            testId="result-list-sidebar-footer-show-results"
                            type="button"
                            marginHorizontal="0px"
                            marginVertical="0px"
                            size="large"
                            display="flex"
                            onClick={() => {
                                setShowFilterSideBar(false)
                            }}
                        >
                            {formatNumber(rowsFound)} Treffer {rowsFound > 0 ? 'anzeigen' : ''}
                        </ClientRoutingAnchorLink>
                        {rowsFound === 0 && (
                            <Fragment>
                                <Box testId="result-list-sidebar-footer-no-results-mobile" marginVertical="s">
                                    <Box textAlign="center" marginBottom="s">
                                        <Text fontSize="s" display="block">
                                            Deine Suche hat leider <strong>keine Treffer</strong> erzielt.
                                        </Text>
                                        <Text fontSize="s" display="block">
                                            Wir suchen für dich weiter.
                                        </Text>
                                    </Box>
                                    <Box display="flex" justifyContent="center">
                                        <CreateSearchAgentButton
                                            maxWidth="400px"
                                            searchAgentCreateLink={searchAgentCreateLink}
                                            searchAgentOptionsLink={searchAgentOptionsLink}
                                            setSearchAgentUIState={setSearchAgentUIState}
                                            size="large"
                                            variant="outline"
                                            clickActionEvent="search_result_list_search_agent_click_empty_result"
                                            taggingData={currentSearchResult.taggingData}
                                        />
                                    </Box>
                                </Box>
                            </Fragment>
                        )}
                    </Box>
                </Box>
            </Box>
            {showFilterSideBar &&
                (isSafariMacOS() ? (
                    <BlockBodyScrollWithSafariMacOSWorkaround />
                ) : isOldiOS ? (
                    <BlockOldiOSBodyScroll />
                ) : (
                    <BlockBodyScroll />
                ))}
        </Fragment>
    )
}

// iOS <= 12 does not support hiding scrollbars on body by setting overflow:hidden, so we apply a different workaround
const useIsOldiOS = () => {
    const parsedUA = useUserAgent().uaParserResult
    if (!parsedUA) {
        return false
    }
    if (parsedUA.browser.name?.toLowerCase() !== 'mobile safari') {
        return false
    }
    if (!parsedUA.os.version) {
        return false
    }
    const [majorString] = parsedUA.os.version.split('.')
    const major = parseInt(majorString, 10)
    if (isNaN(major)) {
        return false
    }

    return major <= 12
}

const BlockOldiOSBodyScroll = () => {
    const [active, setActive] = useState(false)
    const [scrollY, setScrollY] = useState(0)
    const scrollYRef = useRef(scrollY)
    scrollYRef.current = scrollY
    useEffect(() => {
        setScrollY(window.scrollY)
        setActive(true)
        return () => {
            const oldScrollY = scrollYRef.current
            // needs to be deferred because the body position needs to be already reset to the default when the scroll position is set
            setTimeout(() => {
                window.scrollTo(0, oldScrollY)
            }, 1)
        }
    }, [])

    return active ? <FixedBody scrollY={scrollY} /> : null
}

const FixedBody = createGlobalStyle<{ scrollY: number }>`
    body {
        position: fixed;
        top: -${(p) => p.scrollY}px;
    }
`

// INFO Safari is not standard-conform and does not count the scrollbar width as part of the viewport width.
// That causes media queries based on the viewport width to jump when applying the BlockBodyScroll which hides the scrollbar.
// We fix this here by just not blocking scrolling in the problematic width region.
// It's not ideal, but causes no big issues, only the content behind the filter sidebar will be scrollable too.
const isSafariMacOS = () =>
    typeof window !== 'undefined' &&
    /safari/i.test(window.navigator.userAgent) &&
    !/chrome/i.test(window.navigator.userAgent) &&
    !/iphone/i.test(window.navigator.userAgent) &&
    !/ipad/i.test(window.navigator.userAgent)

const APPROXIMATE_SAFARI_SCROLLBAR_WIDTH = 15

const BlockBodyScrollWithSafariMacOSWorkaround = createGlobalStyle`
    body {
        @media screen and (max-width: ${(p) =>
            p.theme.breakpoints.tablet - APPROXIMATE_SAFARI_SCROLLBAR_WIDTH - 1}px), screen and (min-width: ${(p) =>
            p.theme.breakpoints.tablet + 1}px) {
            overflow: hidden;
        }
    }
`
