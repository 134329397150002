import { Button } from '@wh-components/core/Button/Button'
import { Text } from '@wh-components/core/Text/Text'
import ResetThick from '@wh-components/icons/ResetThick'
import { MarginProps } from '@wh-components/system/space'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const IconContainer = styled.div`
    display: flex;
    margin-right: ${(p) => p.theme.space.xs}px;
    padding: 2px;
    background-color: ${(p) => p.theme.colors.semantic.surface.container.high};
    border-radius: 2px;
`

type ButtonProps = React.ComponentProps<typeof Button>

interface ResetLinkButtonProps extends MarginProps {
    label: string
    onClick: () => void
}

export const ResetLinkButton: FunctionComponent<
    ResetLinkButtonProps & Omit<ButtonProps, 'maxWidth' | 'size' | 'color' | 'variant' | 'aria-label'>
> = ({ label, onClick, testId, ...buttonProps }) => (
    <Button
        testId={testId ? `${testId}-${label}-reset-link` : `${label}-reset-link`}
        maxWidth="288px"
        size="xsmall"
        color="complimentary"
        variant="outline"
        aria-label={`Filter ${label} zurücksetzen`}
        onClick={onClick}
        {...buttonProps}
    >
        <IconContainer>
            <ResetThick color="semantic.foreground.alternate" size={10} />
        </IconContainer>
        <Text truncate={true} fontSize="s" color="palette.raccoon" fontWeight="regular">
            {label}
        </Text>
    </Button>
)
